<template>
  <div class="practiceEnd">
    <div class="container">
      <img src="@/assets/image/other/practiceEnd.png" alt="" />
      <p class="end">练习结束</p>
      <div class="startBox">
        <p>当你准备进入游戏时，请点击</p>
        <span @click="begin">开始</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      source: '',
    };
  },
  methods: {
    begin() {
      if (this.$route.query.source) {
        this.$util.fullScreen();
        this.source = this.$route.query.source;
        this.$router.replace({
          name: this.source,
          query: {
            practice: 1,
          },
        });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .practiceEnd {
    height: 100%;
    display: table;
    font-family: 'SYHeiBold';
    margin: 0 auto;
    .container {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      img {
        width: 210px;
        height: 210px;
        margin-top: -50px;
      }
      .end {
        color: #5f9160;
        font-size: 48px;
        margin-bottom: 13px;
      }
      .startBox {
        line-height: 72px;
        p {
          color: #5f9160;
          font-size: 36px;
          line-height: 72px;
          display: inline-block;
          vertical-align: bottom;
        }
        span {
          width: 170px;
          height: 72px;
          line-height: 60px;
          font-size: 30px;
          color: #ffffff;
          display: inline-block;
          background-image: url('../../assets/image/other/btn2.png');
          background-repeat: no-repeat;
          background-size: cover;
          cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .practiceEnd {
    height: 100%;
    display: table;
    font-family: 'SYHeiBold';
    margin: 0 auto;
    .container {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      img {
        width: 273px;
        height: 273px;
        margin-top: -65px;
      }
      .end {
        color: #5f9160;
        font-size: 62px;
        margin-bottom: 17px;
      }
      .startBox {
        line-height: 94px;
        p {
          color: #5f9160;
          font-size: 47px;
          line-height: 94px;
          display: inline-block;
          vertical-align: bottom;
        }
        span {
          width: 221px;
          height: 94px;
          line-height: 78px;
          font-size: 39px;
          color: #ffffff;
          display: inline-block;
          background-image: url('../../assets/image/other/btn2.png');
          background-repeat: no-repeat;
          background-size: cover;
          cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
        }
      }
    }
  }
}
</style>
